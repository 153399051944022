import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { newFormInpPropType } from "../../@types/user";
import question_ico from "../../svg/question.svg";
import { Alert, Input } from "antd";

import { AttachMoneyOutlined, CurrencyRupee } from "@mui/icons-material";
import NumberFormat from "../../utils/NumberFormat";
import StringOptimizer from "../../utils/StringOptimizer";
import { useDispatch, useSelector } from "react-redux";
import { blurNumberInput, changeNumberInput, dropChangeNumberInput } from "../../redux/calculatorSlice";
import { RootState } from "../../redux/store";
import PercentIcon from '@mui/icons-material/Percent';
import { PercentageOutlined } from "@ant-design/icons";


const Number = ({activeIndex,index,data,currency,outerIndex}:newFormInpPropType)=>{

    const [showMsg,setShowMsg] = useState<boolean>(false);
    const msgCnt = useRef<any>();
    const msgRef = useRef<any>();
    const myCurrency = useSelector((state: RootState) => state.calculator.activeScreen?.currency || "dollar");
    const dispatch = useDispatch();

   


    useEffect(()=>{

        window.addEventListener('click',windowClickFunction);


        return ()=>{
            window.removeEventListener('click',windowClickFunction)
        }

      },[]);

      const windowClickFunction = (e:any)=>{
  
        if(msgCnt.current && msgRef.current){

            if (!msgCnt.current.contains(e.target) && !msgRef.current.contains(e.target)){
                setShowMsg(false);
            }

        }
  
      }

      const changefunc = (e:any)=>{
        
        dispatch(changeNumberInput({
            value: NumberFormat.toNumber(e.target.value),
            outerIndex : outerIndex,
            innerIndex : index
        }))
        
      }

      const blurFunc = (e:any)=>{
        
        dispatch(blurNumberInput({
            value: e.target.value,
            outerIndex : outerIndex,
            innerIndex : index
        }))

      }

      const dropChange = (e:any)=>{

        
        dispatch(dropChangeNumberInput({
            value: e.target.value,
            outerIndex : outerIndex,
            innerIndex : index
        }))
        

      }



    return(
        <>

            <div className="formMainCnt">
                <p className="formTitle">{StringOptimizer.updateCurrencyFormat(data?.name || "",currency!)}</p>
                <div className={`formInputCnt ${data?.error && "error"}`}>
                    
                    {
                        data?.currency &&
                        <>
                        {
                            myCurrency === "dollar" &&
                            <AttachMoneyOutlined className="currencyIcon" />

                        }
                        {
                            myCurrency === "rupee" &&
                            <CurrencyRupee className="currencyIcon" />
                        }
                        </>
                    }
                    

                <Input 
                    className="mainInput" 
                    type="text" 
                    min={1}
                    style={{flex:"1"}}
                    value={
                        myCurrency === "dollar" ? NumberFormat.toUSstandard(data?.value) : NumberFormat.toINDstandard(data?.value)
                    }
                    onChange={changefunc}
                    disabled={data?.disabled}
                    onBlur={blurFunc}
                     />

                {
                    data?.secDrop &&
                    <>
                    <select 
                    className="mainInput myselect" 
                    value={data?.secDrop.value} 
                    onChange={dropChange} 
                    >
                        {
                            data?.secDrop.list.map((elem,index)=>(
                                <option key={index} value={elem.value}>{elem.label}</option>
                            ))
                        }
                    </select>
                    </>
                }
                {
                    data?.percent &&
                    <PercentageOutlined className="percentIcon"  />
                }

                <img className="formQuestion" src={question_ico} alt="show input field description button"
                onClick={()=> setShowMsg(true)}
                ref={msgRef}
                 />
                </div>

                {
                    data?.error &&
                    <Alert 
                    style={{fontSize:"12px",padding:"5px 10px"}} 
                    description="Mandatory Field" 
                    type="error" 
                    showIcon={false} />
                }
                {
                    showMsg &&
                    <div className="formMsg" ref={msgCnt}>
                        <p>This is just for demo purpose. I want to declare that it can be a part of the job. 
                        This is just for demo purpose. I want to declare that it can be a part of the job. 
                        This is just for demo purpose. I want to declare that it can be a part of the job.
                        </p>
                    </div>
                }
            </div>
        </>
    )

}

export default Number;