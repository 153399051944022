import Style from "../../styles/output.module.css";
import { filteredResulttype } from "../../@types/user";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import NumberFormat from "../../utils/NumberFormat";

import { AttachMoneyOutlined, CurrencyRupee, Percent } from "@mui/icons-material";
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import ScrollerWrapper from "../scrollerWrapper";

type propType = {
    myresult: filteredResulttype[],
    prep:{
        name:string,
        data:{
            name:string,
            value:string[]
        }[],
        servicableYear:number,
        yearsRemaining:number
    }[][],
    setPrep:(a:any)=> void
}

const TableScreen = ({myresult,prep,setPrep}:propType)=>{

    const allScenario = useSelector((state: RootState) => state.calculator.tabs[state.calculator.activeTab].scenario);
    const myCurrency = useSelector((state: RootState) => state.calculator.activeScreen?.currency || "dollar");
      
    const [result,setResult] = useState<{
        name:string,
        data:{
            name:string,
            value:number[]
        }[],
        servicableYear:number
    }[][]>([]);


    useEffect(()=>{
        if(prep.length < 1) return;


        calculate();
        
    },[prep])


    const calculate = ()=>{

        let localPrep : {
            name:string,
            data:{
                name:string,
                value:number[]
            }[],
            servicableYear:number
        }[][] = [];
      

        myresult.forEach((elem,ind) => {

            let x:{
                name:string,
                data:{name:string,value:number[]}[],
                servicableYear:number
            }[] = [];

            elem.forEach((element,index) => {
    
                let month = prep[ind][index].servicableYear;
    
                let Volume:number[] = prep[ind][index].data[0].value.map(elem=> parseFloat( parseFloat(elem).toFixed(2) ));
                let Inflation:number[] = prep[ind][index].data[1].value.map(elem=> parseFloat( parseFloat(elem).toFixed(2) ));
                let Lta:number[] = prep[ind][index].data[2].value.map(elem=> parseFloat( parseFloat(elem).toFixed(2) ));
                let Tax:number[] = prep[ind][index].data[3].value.map(elem=> parseFloat( parseFloat(elem).toFixed(2) ));
                let Wacc:number[] = prep[ind][index].data[4].value.map(elem=> parseFloat( parseFloat(elem).toFixed(2) ));
    
                let lastSales = 0;
                let sales:number[] = Array.from({length:month+1})
                .map((elem,ind)=> {
                    if(ind === 0 || ind > element.cashFlow.yearsRemaining ) return 0;
    
                    if(ind !==1){
    
                        lastSales =  parseFloat( ( (lastSales + ((Lta[ind]/100) * lastSales) )).toFixed(2) );
                        return lastSales || 0;
                    }
                    else{
                        lastSales = parseFloat( ( Volume[ind] * element.cashFlow.cavities * element.cashFlow.sellingPrice ).toFixed(2) );
                        return lastSales || 0;
                    }
                });

                let scrapSales:number[] = Array.from({length:month+1})
                .map((elem,ind)=> {
                    if(ind === 0 || ind > element.cashFlow.yearsRemaining ) return 0;
    
                    return element.cashFlow.scrapSales;
                });
    
                
                let totalSales:number[] = sales
                .map((elem,ind)=> {
    
                    return parseFloat(( element.cashFlow.tables.sv[ind] + elem + scrapSales[ind] ).toFixed(2)) || 0;
                });
      
                
                let lastMat = 0;
                let Materials:number[] = Array.from({length:month+1})
                .map((elem,ind)=> {
                    if(ind === 0 || ind > element.cashFlow.yearsRemaining ) return 0;
    
                    if(ind !==1){
    
                        lastMat =  parseFloat( ( (lastMat + ((Inflation[ind]/100) * lastMat) )).toFixed(2) );
                        return lastMat || 0;
                    }
                    else{
                        lastMat = parseFloat( (element.output[0].data[3].value ).toFixed(2) );
                        return lastMat || 0;
                    }
                });
    
                let lastDl = 0;
                let Dl:number[] = Array.from({length:month+1})
                .map((elem,ind)=> {
                    if(ind === 0 || ind > element.cashFlow.yearsRemaining) return 0;
    
                    if(ind !==1){
    
                        lastDl =  parseFloat( ( lastDl + ((Inflation[ind]/100) * lastDl ) ).toFixed(2) );
                        return lastDl || 0;
                    }
                    else{
                        lastDl = parseFloat( element.output[0].data[4].value.toFixed(2) );
                        return lastDl || 0;
                    }
                });
    
                let lastOh = 0;
                let Oh:number[] = Array.from({length:month+1})
                .map((elem,ind)=> {
                    if(ind === 0 || ind > element.cashFlow.yearsRemaining) return 0;
    
                    if(ind !==1){
    
                        lastOh =  parseFloat( ( lastOh + ((Inflation[ind]/100) * lastOh ) ).toFixed(2) );
                        return lastOh || 0;
                    }
                    else{
                        lastOh = parseFloat( element.output[0].data[5].value.toFixed(2) );
                        return lastOh || 0;
                    }
                });
    
                let lastAbc = 0;
                let Abc:number[] = Array.from({length:month+1})
                .map((elem,ind)=> {
                    if(ind === 0 || ind > element.cashFlow.yearsRemaining) return 0;
    
                    if(ind !==1){
    
                        lastAbc =  parseFloat( ( lastAbc + ((Inflation[ind]/100) * lastAbc ) ).toFixed(2) );
                        return lastAbc || 0;
                    }
                    else{
                        lastAbc = parseFloat( element.output[0].data[6].value.toFixed(2) );
                        return lastAbc || 0;
                    }
                });
    
                let lastCcm = 0;
                let Ccm:number[] = Array.from({length:month+1})
                .map((elem,ind)=> {
                    if(ind === 0 || ind > element.cashFlow.yearsRemaining) return 0;
    
                    if(ind !==1){
    
                        lastCcm =  parseFloat( ( lastCcm + ((Inflation[ind]/100) * lastCcm ) ).toFixed(2) );
                        return lastCcm || 0;
                    }
                    else{
                        lastCcm = parseFloat( element.output[0].data[7].value.toFixed(2) );
                        return lastCcm || 0;
                    }
                });
    
          
                let Pbt:number[] = Array.from({length:month+1})
                .map((elem,ind)=> {
                    
                    return parseFloat( (sales[ind] - Materials[ind] - Dl[ind] - Oh[ind]).toFixed(2) ) || 0;
                });
    
              
                let Pat:number[] = Array.from({length:month+1})
                .map((elem,ind)=> {
                    if(Pbt[ind] > 0){
                        return parseFloat( (Pbt[ind] * ((100 - Tax[ind])/100 ) ).toFixed(2) ) || 0;
                    }
                    else{
                        return Pbt[ind] || 0;
                    }
                });
    
                let Cc:number[] = Array.from({length:month+1})
                .map((elem,ind)=> {
                    return parseFloat( (totalSales[ind] - Materials[ind] - Dl[ind] - Abc[ind]).toFixed(2) ) || 0;
                });
    
                let Imp:number[] = Array.from({length:month+1})
                .map((elem,ind)=> {
                    if(ind > 1)
                    return parseFloat( (Cc[ind] - Cc[1]).toFixed(2) ) || 0;
                    else
                    return 0;
                });
    
                
                let cashFlow:number[] = getCashOutflow(Cc,ind,index,Tax);
                let patforTable = Pat.map((elem,ind)=> (ind===0) ? cashFlow[0] : elem ).filter(el=> el != 0);
    
                x = [
                    ...x,
                    {
                        name:element.name,
                        data:[
                            {
                                name:'Sales',
                                value:sales
                            },
                            {
                                name:"Salvage Sales",
                                value:element.cashFlow.tables.sv
                            },
                            {
                                name:"Scrap Return Sales",
                                value:scrapSales
                            },
                            {
                                name:"Total Sales",
                                value:totalSales
                            },
                            {
                                name:'Materials',
                                value:Materials
                            },
                            {
                                name:'Direct Labor',
                                value:Dl
                            },
                            {
                                name:'STD OH',
                                value:Oh
                            },
                            {
                                name:'ABC',
                                value:Abc
                            },
                            {
                                name:'CCM',
                                value:Ccm
                            },
                            {
                                name:'PBT',
                                value:Pbt
                            },
                            {
                                name:'PAT',
                                value:Pat
                            },
                            {
                                name:'BT CC',
                                value:Cc
                            },
                            {
                                name:'IMP/ERO',
                                value:Imp
                            },
                            {
                                name:'Cash Outflow',
                                value:[(myresult[ind][index].cashFlow.tinv + myresult[ind][index].cashFlow.tc)*-1]
                            },
                            {
                                name:'AT CC',
                                value:cashFlow
                            },
                            {
                                name:'Enterprise NPV',
                                value:[ NPV( cashFlow, Wacc.map(elem=> (elem/100 )) ) ]
                            },
                            {
                                name:'Enterprise IRR',
                                value:[IRR(cashFlow)]
                            },
                            {
                                name:'STD Cost NPV',
                                value:[ NPV( patforTable, Wacc.map(elem=> (elem/100 )) ) ]
                            },
                            {
                                name:'STD Cost IRR',
                                value:[IRR(patforTable)]
                            }
                        ],
                        servicableYear:prep[ind][index].servicableYear
                    }
                ]
                
            });

            localPrep.push(x);
        
        });


        setResult(localPrep);

    }

    const getCashOutflow = (cc:number[],ind:number,index:number,tax:number[])=>{
       
        let table = myresult[ind][index].cashFlow.tables;
        let atcc:number[] = [];


        cc.forEach((mycc,ind) => {
             // All Taxible Income and Calculated Taxible Income
             let myati = mycc + table.sv[ind];
             let mycti = parseFloat((myati - (table.ds[ind] + table.bv[ind] )).toFixed(2));
             let taxible = mycti;
             let comparable = ind === 0 ? (myresult[ind][index].cashFlow.enterpriseProfitLoss|| 0) : mycc;
        

             if(ind === 0){
                if(comparable > 0 && comparable < Math.abs(taxible)){
                    taxible = taxible > 0 ? comparable : comparable * -1;
                }
                else if(comparable <= 0){
                    taxible = 0;
                }
             }
             

             let mytti = (taxible > 0 ?
             parseFloat(( taxible * ( (100 - tax[ind] ) / 100 )).toFixed(2) ) :
             parseFloat(( taxible * ( tax[ind] / 100 )).toFixed(2) ))

             let myaddback = table.ds[ind] + table.bv[ind];
             let myatcc = mytti + myaddback;

             if(ind === 0){

                 myatcc += myresult[ind][index].cashFlow.tc;
                 if(myatcc > 0) myatcc *= -1;
             }

            
             atcc.push(parseFloat(myatcc.toFixed(2)));
        });

        return atcc;

    }

    const NPV = (cashflow:number[], discountRate:number[])=>{

        let sum = 0;

        for(let i=0; i<cashflow.length; i++){
            let x = cashflow[i] / Math.pow((1 + (discountRate[i] ? discountRate[i] : discountRate[0] )), i+1);

            sum += x;
        }

        return parseFloat(sum.toFixed(2));
     
    }

    const IRR = (cashflow:number[], initialGuess = 0.1)=>{
        const maxTries = 100000;
        const delta = 0.001;
        let guess = initialGuess;
        const multiplier = NPV(cashflow, [guess]) > 0 ? 1 : -1;
        let i = 0;
        while ( i < maxTries ) {
          const guessedNPV = NPV(cashflow, [guess]);
          if ( multiplier * guessedNPV > delta ) {
            guess += (multiplier * delta);
            i += 1;
          }
          else break;
        }
        if(i === maxTries)
        return 11011;
        else
        return parseFloat( (guess * 100).toFixed(2) );
        // console.log(`Found IRR = ${guess * 100} in ${i} trials`);
        
    }

    const changeHandler = (aindex:number,tableInd:number,rowInd:number,cellInd:number,val:string)=>{

        let x = [...prep];

        x[aindex][tableInd].data[rowInd].value[cellInd] = val;

        setPrep(x);

    }

    return(
        <div className="chartBoxCnt printable">

            
                {
                    prep.map((aelem,aindex)=>(
          
                        aelem.map((elem,index)=>(
                            <div className={Style.tableCnt} key={`sasa${index}`}>
                                    <h2 className={Style.tableName}>{elem.name}</h2>
                                    <div className={Style.highlightCnt}>

                                        <div className={Style.highlightChild}>

                                            <div className={Style.highlightTile}>
                                                <div className={Style.highlightTleft}>
                                                    Enterprise NPV
                                                </div>
                                                <div className={`
                                                ${Style.highlightTright}
                                                ${result[aindex] && result[aindex][index].data[result[aindex][index].data.length-4].value[0] < 0 && "danger"}
                                                `}>
                                                <>
                                                {
                                                    myCurrency === "dollar" &&
                                                    <AttachMoneyOutlined />
                                                }
                                                {
                                                    myCurrency === "rupee" &&
                                                    <CurrencyRupee />
                                                }
                                                </>
                                                    {
                                                        result[aindex] &&
                                                        <>
                                                        {
                                                            (myCurrency === "dollar") &&
                                                            (NumberFormat.toUSstandard(result[aindex][index].data[result[aindex][index].data.length-4].value[0].toFixed(2)) || 0 )
                                                        }
                                                        {
                                                            myCurrency === "rupee" &&
                                                            (NumberFormat.toINDstandard(result[aindex][index].data[result[aindex][index].data.length-4].value[0].toFixed(2)) || 0)
                                                        }
                                                        </>
                                                    }
                                                </div>
                                            </div>

                                            <div className={Style.highlightTile}>
                                                <div className={Style.highlightTleft}>
                                                    Enterprise IRR
                                                </div>
                                                <div className={`
                                                ${Style.highlightTright}
                                                ${(result[aindex] && result[aindex][index].data[result[aindex][index].data.length-3].value[0] < myresult[aindex][index].cashFlow.irrRate) ? "danger" : "green"}
                                                `}>
                                                {
                                                    result[aindex] && result[aindex][index].data[result[aindex][index].data.length-3].value[0] === 11011 ?
                                                    <AllInclusiveIcon />
                                                    :
                                                    <>
                                                    {result[aindex] && result[aindex][index].data[result[aindex][index].data.length-3].value[0]}
                                                    <Percent />
                                                    </>
                                                }
                                                </div>
                                            </div>

                                        </div>

                                         
                                        <div className={Style.highlightChild}>

                                            <div className={Style.highlightTile}>
                                                <div className={Style.highlightTleft}>
                                                    STD Cost NPV
                                                </div>
                                                <div className={`
                                                ${Style.highlightTright}
                                                ${result[aindex] && result[aindex][index].data[result[aindex][index].data.length-2].value[0] < 0 && "danger"}
                                                `}>
                                                <>
                                                {
                                                    myCurrency === "dollar" &&
                                                    <AttachMoneyOutlined />
                                                }
                                                {
                                                    myCurrency === "rupee" &&
                                                    <CurrencyRupee />
                                                }
                                                </>
                                                {
                                                        result[aindex] &&
                                                        <>
                                                        {
                                                            (myCurrency === "dollar") &&
                                                            (NumberFormat.toUSstandard(result[aindex][index].data[result[aindex][index].data.length-2].value[0].toFixed(2)) || 0 )
                                                        }
                                                        {
                                                            myCurrency === "rupee" &&
                                                            (NumberFormat.toINDstandard(result[aindex][index].data[result[aindex][index].data.length-2].value[0].toFixed(2)) || 0)
                                                        }
                                                        </>
                                                    }
                                                </div>
                                            </div>

                                            <div className={Style.highlightTile}>
                                                <div className={Style.highlightTleft}>
                                                    STD Cost IRR
                                                </div>
                                                <div className={`
                                                ${Style.highlightTright}
                                                ${(result[aindex] && result[aindex][index].data[result[aindex][index].data.length-1].value[0] < myresult[aindex][index].cashFlow.irrRate) ? "danger" : "green"}
                                                `}>
                                                    
                                                {
                                                    result[aindex] && result[aindex][index].data[result[aindex][index].data.length-1].value[0] === 11011 ?
                                                    <AllInclusiveIcon />
                                                    :
                                                    <>
                                                    {result[aindex] && result[aindex][index].data[result[aindex][index].data.length-1].value[0]}
                                                    <Percent />
                                                    </>
                                                }
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    {
                                        elem.servicableYear < 1 &&
                                        <h3 style={{margin:"100px auto",textAlign:"center",width:"100%"}}>No Cashflow to show</h3>
                                    }
                              
                                    
                                    {
                                        elem.servicableYear > 1 &&
                                        <ScrollerWrapper>
                                            <table className={Style.table}>
                                                
                                                <tbody>
                                                <tr>
                                                    <th></th>
                                                    {
                                                        Array.from({length:myresult[aindex][index].cashFlow.tables.atcc.length}).map((elem,ind)=>(
                                                            <th key={`th${ind}`}>
                                                                <div className={Style.tableHeaderBox}>
                                                                    Year {ind}
                                                                </div>
                                                            </th>
                                                        ))
                                                    }
                                                
                                                </tr>
                                        
                                        
                                                {
                                                    elem.data.map((ielem,iind)=>(
                                                        <tr key={`${index}${iind}`}>
                                                            <td>
                                                                <div className={Style.tableLeft}>
                                                                    {ielem.name}
                                                                </div>
                                                            </td>
                                                            {
                                                                
                                                                ielem.value.map((iielem,iiind)=>(
                                                                    <td key={`${index}${iind}${iiind}`}>
                                                                        {
                                                                            (((iiind === 0 && iind <3) || (iiind > elem.yearsRemaining && iind <3 ))) ?
                                                                            <></>
                                                                            :
                                                                            <div className={Style.tableInpCnt}>
                                                                                {
                                                                                    (iind > 0 && iind< 5) &&
                                                                                    <Percent />
                                                                                }
                                                                                <input 
                                                                                type="text" 
                                                                                value={myCurrency === "dollar" ? NumberFormat.toUSstandard(iielem) : NumberFormat.toINDstandard(iielem) } 
                                                                                className={Style.tableInp} 
                                                                                onChange={(e)=> changeHandler(aindex,index,iind,iiind,NumberFormat.toNumber(e.target.value)) }
                                                                                />
                                                                                
                                                                            </div>
                                                                        }
                                                                    </td>
                                                                ))
                                                            }
                                                        </tr>
                                                    ))
                                                }
                                                {
                                                    result[aindex] && result[aindex][index]?.data.map((ielem,iind)=>(
                                                        <tr key={`res${index}${iind}`}>
                                                            <div className={Style.tableLeft}>
                                                                    {ielem.name}
                                                            </div>
                                                            {
                                                                ielem.value.map((iielem,iiind)=>(
                                                                    <td key={`res${index}${iind}${iiind}`} className={`
                                                                    ${( (iind === 18 || iind == 16) && ((ielem.value[0] < myresult[aindex][index].cashFlow.irrRate)  ? "danger" : "green"))}
                                                                    ${( ((iind === 15 || iind == 17)) && ielem.value[0] < 0 ) && "danger"}
                                                                    `}>
                                                                        {
                                                                            (
                                                                                ( (iiind === 0 && iind < 13) ) ||
                                                                                (
                                                                                    (iiind > elem.yearsRemaining && iind !== 1 && iind!==3 && iind !== 11 && iind !== 12 && iind !== 14)
                                                                                )
                                                                                
                                                                            ) ?
                                                                            <>
                                                                            -
                                                                            </>
                                                                            :
                                                                            <>
                                                                            {
                                                                             
                                                                            <div className={Style.cashflowValCnt}>
                                                                                {
                                                                                    (iiind === 0 && (iind === 16 || iind === 18)) ?
                                                                                        (iielem != 11011) &&
                                                                                        <Percent />
                                                                                    :
                                                                                    <>
                                                                                    {
                                                                                        myCurrency === "dollar" &&
                                                                                        <AttachMoneyOutlined />
                                                                                    }
                                                                                    {
                                                                                        myCurrency === "rupee" &&
                                                                                        <CurrencyRupee />
                                                                                    }
                                                                                    </>
                                                                                }
                                                                            
                                                                            {
                                                                                (iiind === 0 && (iind === 16 || iind == 18) && iielem == 11011) ?
                                                                                    <AllInclusiveIcon />
                                                                                :
                                                                                <>
                                                                                {
                                                                                    (myCurrency === "dollar") &&
                                                                                    (NumberFormat.toUSstandard(iielem.toFixed(2)) || 0 )
                                                                                }
                                                                                {
                                                                                    myCurrency === "rupee" &&
                                                                                    (NumberFormat.toINDstandard(iielem.toFixed(2)) || 0)
                                                                                }
                                                                                </>
                                                                            }
                                                                            </div>
                                                                            }
                                                                            </>
                                                                        }
                                                                    </td>
                                                                ))
                                                            }
                                                        </tr>
                                                    ))
                                                }
                                                
                                                </tbody>
                                    

                                            </table>
                                        </ScrollerWrapper>
                                    }
                                    
                                    

                            </div>
                        ))
                 
                    ))
                }
        </div>
    )
}

export default TableScreen;