import { useContext,useState,useEffect,useRef } from 'react';

import logout_logo from "../svg/logout.svg";
import { GlobalContextType } from '../@types/user';
import { useNavigate } from "react-router-dom";
import { globalData } from '../context/globalContext';
import { MenuOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { logout } from '../redux/userSlice';
import { Axios } from '../Axios';
import ModalBox from './modal';
import Flynaut from './branding/Flynaut';
import { resetCalc } from '../redux/calculatorSlice';

const Header = ()=>{

    const { activeTab, setActiveTab } = useContext(globalData) as GlobalContextType;
    const user = useSelector((state: RootState) => state.user.user );
    const [ mobMenuState, setMobMenuState ] = useState<boolean | null>(null); // for opening or closing menu
    const [ signoutConfirm, setSignoutConfirm ] = useState(false);
    const menuHolder = useRef<any>();
    const innerMenuHolder = useRef<any>();

    const navigate = useNavigate();
    const dispatch = useDispatch();


    // signout function
    const signOut = ()=>{

        Axios.post(`/api/v1/signout`,{},{
            headers:{
                Authorization:user?.token
            }
        })
        .then(result=> {

            dispatch( logout() );

        })
        .catch(err=>{

        })
    }


    // hiding active mobile menu when switched to pc mode
    useEffect(()=>{

        window.addEventListener("resize",resizeFunc);

        return ()=>{
            window.removeEventListener("resize",resizeFunc);
        }

    },[]);


    const resizeFunc = ()=>{
        if(window.innerWidth >=800){
            setMobMenuState(false);
        }
    }

    // hiding or showing menu based on mobMenuState value.
    useEffect(()=>{
        if(mobMenuState === null) return;

        if(mobMenuState && menuHolder.current && innerMenuHolder.current){
            menuHolder.current.style.display = "flex";
            setTimeout(()=>{
                innerMenuHolder.current.style.transform = "translateX(0)";
            },100);
        }
        else if(!mobMenuState && menuHolder.current && innerMenuHolder.current){
            innerMenuHolder.current.style.transform = "translateX(-100%)";
            setTimeout(()=>{
                menuHolder.current.style.display = "none";
            },500);
        }

    },[mobMenuState]);
    

    // Redirection function.
    const redirectHandler = (num:Number)=>{
       
        setActiveTab(num);

        if(num === 0){
            navigate("/home");
        }
        else if(num === 1){
            navigate("/histories");
        }
        else if(num === 2){
            navigate("/users");
        }

        
    }

    return(
        <>
        <div id="header">
            {/* <img className="logo" src="/images/cco-small.png" alt="brand_logo" /> */}

            <Flynaut className="logo" />

            <div className="pcLinks">
                <ul>
                    <li className={activeTab == 0 ? `active` : ''} onClick={()=> redirectHandler(0) }>
                        SAPI calculator
                    </li>
                    <li className={activeTab == 1 ? `active` : ''} onClick={()=> redirectHandler(1) }>
                        Histories
                    </li>
                   {
                    user?.role === 2 &&
                    <li className={activeTab == 2 ? `active` : ''} onClick={()=> redirectHandler(2) }>
                        Users
                    </li>
                   }

                </ul>

                <img className="logoutBtn" src={logout_logo} alt="logout_btn" onClick={()=> setSignoutConfirm(true)} />
            </div>

            <MenuOutlined className='menuBtn' onClick={()=> setMobMenuState(true)  } />

        </div>

        <div id='mobMenuCnt' ref={menuHolder}>
            <div id='mobMenuPanel' ref={innerMenuHolder}>
            <div className='mobMenuPanelTop'>
                <img className="mobmenulogo" src="/images/cco-small.png" alt="brand_logo" />
                <img className="logoutBtn" src={logout_logo} alt="logout_btn" onClick={signOut} />

            </div>

            <Button 
            className={`mobMenuItem ${activeTab == 0 && 'mobItemActive'}`}
            onClick={()=> redirectHandler(0) }
            type='text'>
                SAPI calculator
            </Button>
            <Button 
            className={`mobMenuItem ${activeTab == 1 && 'mobItemActive'}`}
            onClick={()=> redirectHandler(1) }
            type='text'>
                Histories
            </Button>
            <Button 
            className={`mobMenuItem ${activeTab == 2 && 'mobItemActive'}`}
            onClick={()=> redirectHandler(2) }
            type='text'>
                Clients
            </Button>

            </div>

            <div id='mobMenuClose' onClick={()=> setMobMenuState(false) } >
                
            </div>

        </div>
        {
            signoutConfirm &&
            <ModalBox
            open={signoutConfirm}
            onClose={()=> setSignoutConfirm(false)}
            header='Confirm !'
            message='Are you sure to sign out !'
            onOk={()=>{
                signOut()
            }}
            onCancel={()=> setSignoutConfirm(false) }
            />
        }
        </>
    );

}

export default Header;