import { CalculatorType } from "../@types/user";

class Calculator{

    getCalculatorfields({type}:{type:number}):CalculatorType{
        return [
            {
                name:"Job & Tool Specification",
                cmp:false,
                child:[
                    {
                        name:`${type === 0 ? "Project" : "Scenario"} Description`,
                        type:"text",
                        value:"",
                        error:false,
                        msg:""
                    },
                    {
                        name:"Part no / Job no",
                        type:"text",
                        value:"",
                        error:false,
                        msg:"",
                        required:true
                    },
                    {
                        name:"Number of Cavities",
                        type:"nestedNum",
                        value:"",
                        error:false,
                        msg:"",
                        required:true,
                        popupArrayName:"Cavity",
                        fields:[
                            {name:"Sell Price",type:"Cnumber",currency:true},
                            {name:"BOM Cost",type:"Cnumber",currency:true}
                        ],
                        entries:[]
                    },
                    {
                        name:"Are the cavities symmetrical",
                        type:"bool",
                        value:"yes",
                        error:false,
                        msg:"",
                        required:true,
                        disabled:true
                    }  
                ],
                pageErr:false
            },
            {
                name : "Sales, Volume and Material Information",
                cmp:false,
                child:[
                    {
                        name:"Average Sell Price Per Part($/part)",
                        type:"num",
                        value:"",
                        error:false,
                        msg:"",
                        required : true,
                        currency:true
                    },
                    {
                        name:"Average BOM Per Part ($/part)",
                        type:"num",
                        value:"",
                        error:false,
                        msg:"",
                        required : true,
                        currency:true
                    },
                    {
                        name:"Annual Shots per year",
                        type:"num",
                        value:"",
                        error:false,
                        msg:"",
                        required:true
                    },
                    {
                        name:"Is there an upper Capacity Planning Volume limit?",
                        type:"decisive",
                        value:"",
                        error:false,
                        msg:""
                    },
                    {
                        name:"Is there a lower Capacity Planning Volume limit?",
                        type:"decisive",
                        value:"",
                        error:false,
                        msg:""
                    },                     
                    {
                        name:"Months Remaining in Job",
                        type:"num",
                        value:"60",
                        error:false,
                        msg:"",
                        required:true
                    },
                    {
                        name:"Weighted Average Cost of Capital %",
                        type:"num",
                        value:"10",
                        error:false,
                        msg:"",
                        percent:true
                    },
                    {
                        name:"Total Tax Rate %",
                        type:"num",
                        value:"35",
                        error:false,
                        msg:"",
                        required:true,
                        percent:true
                    },
                    {
                        name:"Enter current Enterprise profit or loss Amount.",
                        type:"num",
                        value:"",
                        error:false,
                        msg:"",
                        secDrop:{
                            list:[
                                {value:11,label:"Profit"},
                                {value:12,label:"Loss"}
                            ],
                            value:11
                        },
                        currency:true
                    },
                    {
                        name:"Do you want to include Inflation Rate(%) ?",
                        type:"decisive",
                        value:"",
                        error:false,
                        msg:"",
                        percent:true
                    },
                    {
                        name:"Do you have an IRR Hurdle Rate(%) ?",
                        type:"decisive",
                        value:"",
                        error:false,
                        msg:"",
                        percent:true
                    },
                    {
                        name:"Do you have an ATROS Hurdle Rate(%) ?",
                        type:"decisive",
                        value:"",
                        error:false,
                        msg:"",
                        percent:true
                    },
                    {
                        name:"Scrap recovery sales",
                        type:"num",
                        value:"",
                        error:false,
                        msg:"",
                        secDrop:{
                            list:[
                                {value:0,label:"Weekly"},
                                {value:1,label:"Monthly"},
                                {value:2,label:"Yearly"}
                            ],
                            value:0
                        },
                        currency:true
                    }
                ],
                pageErr:false
    
            },
            {
                name:"Press and Operator Burden Summary",
                cmp:false,
                child:[
                    {
                        name:`Standard Cost Overhead Rate ($/hr)`,
                        type:"num",
                        value:"",
                        error:false,
                        msg:"",
                        required:true,
                        currency:true
                    },
                    {
                        name:"Machine Burden Rate ($/hr)",
                        type:"num",
                        value:"",
                        error:false,
                        msg:"",
                        currency:true,
                        required:true
                    },
                    {
                        name:"SG&A ($/hr)",
                        type:"num",
                        value:"",
                        error:false,
                        msg:"",
                        currency:true
                    }
                ],
                pageErr:false
            },
            {
                name:"Direct Labor, Break Relief, & Wage Operator Summary",
                cmp:false,
                child:[
                    {
                        name:"Numbers of operators per shift/job",
                        type:"num",
                        value:"",
                        error:false,
                        msg:"",
                        required:true
                    },
                    {
                        name:"Is this a continuous operation",
                        type:"bool",
                        value:"yes",
                        error:false,
                        msg:""
                    },
                    {
                        name:"Break Relief Ratio %",
                        type:"num",
                        value:"",
                        error:false,
                        msg:"",
                        required:true,
                        percent:true
                    },
                    {
                        name:"Average Operator Base Wage $/hr",
                        type:"num",
                        value:"",
                        error:false,
                        msg:"",
                        currency:true,
                        required:true
                    },
                    {
                        name:"Hourly Employee Benefit Ratio %",
                        type:"num",
                        value:"",
                        error:false,
                        msg:"",
                        percent:true
                    },
                    {
                        name:"Average Operator Fully Fringed Wage $/hr",
                        type:"num",
                        value:"",
                        error:false,
                        msg:"",
                        currency:true,
                        required:true
                    },
                    {
                        name:"Overtime Premium Percentage",
                        type:"popup",
                        value:"",
                        error:false,
                        msg:"",
                        data:[
                            {
                                name:"Saturdays",
                                value:"",
                                percent:true
                            },
                            {
                                name:"Sundays",
                                value:"",
                                percent:true
                            },
                            {
                                name:"Holidays",
                                value:"",
                                percent:true
                            }
                        ],
                        required:true

                    }
                ],
                pageErr:false
            },
            {
                name:"Operations Overview",
                cmp:false,
                child:[
                    {
                        name:"Downtime",
                        type:"num",
                        value:"",
                        error:false,
                        msg:""
                    },
                    {
                        name:"OEE",
                        type:"num",
                        value:"",
                        error:false,
                        msg:"",
                        required:true,
                        percent:true
                    },
                    {
                        name:"Scrap",
                        type:"num",
                        value:"",
                        error:false,
                        msg:"",
                        percent:true
                    },
                    {
                        name:"Cycle Time",
                        type:"num",
                        value:"",
                        error:false,
                        msg:"",
                        required:true
                    }
                ],
                pageErr:false
            },
            {
                name:"Operating Pattern",
                cmp:false,
                child:[
                    {
                        name:"How many shifts per day?",
                        type:"num",
                        value:"",
                        error:false,
                        msg:"",
                        required:true
                    },
                    {
                        name:"How many hours per shift?",
                        type:"num",
                        value:"",
                        error:false,
                        msg:"",
                        required:true
                    },
                    {
                        name:"How many days per week?",
                        type:"num",
                        value:"",
                        error:false,
                        msg:"",
                        required:true
                    },
                    {
                        name:"How many operating days per year?",
                        type:"num",
                        value:"",
                        error:false,
                        msg:"",
                        required:true
                    },
                    {
                        name:"Overtime Premium 1 above X yearly hours?",
                        type:"num",
                        value:"",
                        error:false,
                        msg:""
                    },
                    {
                        name:"Overtime Premium 2 above Y yearly hours?",
                        type:"num",
                        value:"",
                        error:false,
                        msg:""
                    }
    
                ],
                pageErr:false
            },
            {
                name:"Activity Based Variance",
                cmp:false,
                child:[
                    {
                        name:"How many Variable Indirect labour added?",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:"",
                        popupName:"Enter the number of Variable Indirect labour added!",
                        popupArrayName:"Variable Indirect Labour",
                        fields:[
                            {name:"Name of the Position",type:"string",required:true},
                            {name:"Annual Fully Fringed Wage", type:"Cnumber",currency:true,required:true}
                        ],
                        entries:[]
                    },
                    {
                        name:"How many Variable Indirect labour removed?",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:"",
                        popupName:"Enter the number of Variable Indirect labour removed!",
                        popupArrayName:"Variable Indirect Labour",
                        fields:[
                            {name:"Name of the Position",type:"string",required:true},
                            {name:"Annual Fully Fringed Wage", type:"Cnumber",currency:true,required:true}
                        ],
                        entries:[]
                    },
                    {
                        name:"How many Fixed Indirect labour added?",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:"",
                        popupName:"Enter the number of Fixed Indirect labour added!",
                        popupArrayName:"Fixed Indirect Labour",
                        fields:[
                            {name:"Name of the Position",type:"string",required:true},
                            {name:"Annual Fully Fringed Wage", type:"Cnumber",currency:true,required:true}
                        ],
                        entries:[]
                    },
                    {
                        name:"How many Fixed Indirect labour removed?",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:"",
                        popupName:"Enter the number of Fixed Indirect labour removed!",  
                        popupArrayName:"Fixed Indirect Labour",
                        fields:[
                            {name:"Name of the Position",type:"string",required:true},
                            {name:"Annual Fully Fringed Wage", type:"Cnumber",currency:true,required:true}
                        ],
                        entries:[]
                    },
                    {
                        name:"Utilities Increase/Reduction",
                        type:"num",
                        value:"",
                        error:false,
                        msg:"",
                        currency:true
                    },
                    {
                        name:"Additional Activity-based Line Items required?",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:"",
                        popupName:"Enter the number of Activity-based Line Items!",  
                        popupArrayName:"Activity-based Item",
                        fields:[
                            {name:"Description",type:"string",required:true},
                            {name:"Annual Cost", type:"Cnumber",currency:true,required:true}
                        ],
                        entries:[]
                    },
                    {
                        name:"Number of Other Variable Line Items?",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:"",
                        popupName:"Enter the number of Other Variable Line Items!",
                        popupArrayName:"Variable Item",
                        fields:[
                            {name:"Description",type:"string",required:true},
                            {name:"Annual Cost", type:"Cnumber",currency:true,required:true}
                        ],
                        entries:[]
                    },
                    {
                        name:"Allocation for shared resources",
                        type:"num",
                        value:"",
                        error:false,
                        msg:"",
                        currency:true
                    },
                ],
                pageErr:false
            },
            {
                name:"Investment",
                cmp:false,
                child:[
                    {
                        name:"Project Investment",
                        type:"num",
                        value:"",
                        error:false,
                        msg:"",
                        currency:true
                    },
                    {
                        name:"Capital Investment",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:"",
                        popupName:"How many equipments?",
                        popupArrayName:"Equipment",
                        fields:[
                            {name:"Description",type:"string",required:true},
                            {name:"Depreciation schedule",type:"Cnumber",required:true},
                            {name:"Cost", type:"Cnumber",currency:true,required:true},
                            {name:"Salvage Value", type:"Cnumber",currency:true},
                            {name:"What year in the life we will salvage", type:"Cnumber"}
                        ],
                        entries:[]
                    },
                    {
                        name:"Installation Rigging Expenses",
                        type:"num",
                        value:"",
                        error:false,
                        msg:"",
                        currency:true
                    },
                    {
                        name:"Any other Investment Expense",
                        type:"num",
                        value:"",
                        error:false,
                        msg:"",
                        currency:true
                    }
                ],
                pageErr:false
            },
            {
                name:"Off Standard Costing",
                cmp:false,
                child:[
                    {
                        name:"Outbound Premium Freight ",
                        type:"num",
                        value:"",
                        error:false,
                        msg:"",
                        secDrop:{
                            list:[
                                {value:0,label:"Weekly"},
                                {value:1,label:"Monthly"},
                                {value:2,label:"Yearly"}
                            ],
                            value:0
                        },
                        currency:true
                    },
                    {
                        name:"Inbound Premium Freight",
                        type:"num",
                        value:"",
                        error:false,
                        msg:"",
                        secDrop:{
                            list:[
                                {value:0,label:"Weekly"},
                                {value:1,label:"Monthly"},
                                {value:2,label:"Yearly"}
                            ],
                            value:0
                        },
                        currency:true
                    },
                    {
                        name:"Quality Charges",
                        type:"num",
                        value:"",
                        error:false,
                        msg:"",
                        secDrop:{
                            list:[
                                {value:0,label:"Weekly"},
                                {value:1,label:"Monthly"},
                                {value:2,label:"Yearly"}
                            ],
                            value:0
                        },
                        currency:true
                    },
                    {
                        name:"Third Party Sorting Charges GP-12",
                        type:"num",
                        value:"",
                        error:false,
                        msg:"",
                        secDrop:{
                            list:[
                                {value:0,label:"Weekly"},
                                {value:1,label:"Monthly"},
                                {value:2,label:"Yearly"}
                            ],
                            value:0
                        },
                        currency:true
                    },
                    {
                        name:"Internal Sorting Charges",
                        type:"num",
                        value:"",
                        error:false,
                        msg:"",
                        secDrop:{
                            list:[
                                {value:0,label:"Weekly"},
                                {value:1,label:"Monthly"},
                                {value:2,label:"Yearly"}
                            ],
                            value:0
                        },
                        currency:true
                    },
                    {
                        name:"Offline Charges",
                        type:"num",
                        value:"",
                        error:false,
                        msg:"",
                        secDrop:{
                            list:[
                                {value:0,label:"Weekly"},
                                {value:1,label:"Monthly"},
                                {value:2,label:"Yearly"}
                            ],
                            value:0
                        },
                        currency:true
                    },
                    {
                        name:"Shut down Charges",
                        type:"num",
                        value:"",
                        error:false,
                        msg:"",
                        secDrop:{
                            list:[
                                {value:0,label:"Weekly"},
                                {value:1,label:"Monthly"},
                                {value:2,label:"Yearly"}
                            ],
                            value:0
                        },
                        currency:true
                    },
                    {
                        name:"Any other Off standard Cost",
                        type:"num",
                        value:"",
                        error:false,
                        msg:"",
                        secDrop:{
                            list:[
                                {value:0,label:"Weekly"},
                                {value:1,label:"Monthly"},
                                {value:2,label:"Yearly"}
                            ],
                            value:0
                        },
                        currency:true
                    }
                ],
                pageErr:false
            }
        ]
    }

    createNewCopyOfCalcFields(val:CalculatorType){
        let x = val.map(elem=> ({...elem,child:[...elem.child].map(el=> ({...el, entries:(el.entries ? [...el.entries!!]:[]).map(e=> ([...e].map(e2=> ({...e2})))), secDrop:el.secDrop && {...el.secDrop}   }))}));
        return x;
    }
    getRealTime(){
        let date = new Date().toDateString();
        let time = new Date().toLocaleTimeString();

        return `${date}, ${time}`;
    }
}

export default new Calculator;