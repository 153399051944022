import { useContext, useEffect, useRef, useState } from "react";
import { newFormInpPropType } from "../../@types/user";
import question_ico from "../../svg/question.svg";
import { Alert, Input } from "antd";
import NumberFormat from "../../utils/NumberFormat";
import { useDispatch, useSelector } from "react-redux";
import { decisiveInputchange } from "../../redux/calculatorSlice";
import { RootState } from "../../redux/store";
import { PercentageOutlined } from "@ant-design/icons";


type currencyType={
    myCurrency:string
}

const Decisive = ({activeIndex,index,data,outerIndex,currency}:newFormInpPropType)=>{

    const myCurrency = useSelector((state: RootState) => state.calculator.activeScreen?.currency || "dollar");
    const [showMsg,setShowMsg] = useState<boolean>(false);
    const msgCnt = useRef<any>();
    const msgRef = useRef<any>();

    const [decisive,setDecisive] = useState(data?.value ? "yes" : "no");
    let isFocusable = data?.value ? false : true;
    const decisiveHolder = useRef<any>();
    const decisiveSelect = useRef<any>();
    const container = useRef<any>();

    const dispatch = useDispatch();


    useEffect(()=>{
        setDecisive(data?.value ? "yes" : "no");
    },[data?.value]);

    
    useEffect(()=>{

        window.addEventListener('click',windowClickFunction);

        return ()=>{
            window.removeEventListener('click',windowClickFunction)
        }

      },[]);

      useEffect(()=>{
       
        if(decisive === "yes" && isFocusable){
             if(decisiveHolder.current){
                 decisiveHolder.current.focus();
             }
             
         }
 
       },[decisive])

      const windowClickFunction = (e:any)=>{
  
        if(msgCnt.current && msgRef.current){

            if (!msgCnt.current.contains(e.target) && !msgRef.current.contains(e.target)){
                setShowMsg(false);
            }

        }

        if(container.current && decisiveHolder.current){


            if (!container.current.contains(e.target)){
                if(!decisiveHolder.current.input.value){
                    setDecisive("no");
                }
            }
    
            }
  
      }

      const changefunc = (e:any)=>{

        if(e.target.value === "0"){
            e.target.value = ""
        }

        dispatch(decisiveInputchange({
            value: NumberFormat.toNumber(e.target.value),
            outerIndex : outerIndex,
            innerIndex : index
        }))
        
      }

    return(
        <>
            <div className="formMainCnt">
                <p className="formTitle">{data?.name}</p>
                <div className={`formInputCnt ${data?.error && "error"}`} ref={container}>

                   {
                    decisive === "yes" &&
                    <>
                    <Input 
                    className="mainInput" 
                    type="text" 
                    style={{flex:"1"}}
                    value={
                        myCurrency === "dollar" ? NumberFormat.toUSstandard(data?.value) : NumberFormat.toINDstandard(data?.value)
                    }
                    onChange={changefunc}
                    min={1}
                    ref={decisiveHolder}

                    />
                    {
                        data?.percent &&
                        <PercentageOutlined className="percentIcon"  />
                    }
                    </>
                   }


                    <select 
                    ref={decisiveSelect}
                    className="mainInput myselect" 
                    value={decisive} 
                    onChange={(e)=> {
                        // console.log(decisiveHolder);
                        if(e.target.value === "no"){

                            let x = {...e};
                            x.target.value = "";
        
                            changefunc(e);
                        }
                        setDecisive(e.target.value)
                    }}
                    >
                        <option value="no">No</option>
                        <option value="yes">Yes</option>
                    </select>

                <img className="formQuestion" src={question_ico} alt="show input field description button"
                onClick={()=> setShowMsg(true)}
                ref={msgRef}
                 />
                </div>

                {
                    data?.error &&
                    <Alert 
                    style={{fontSize:"12px",padding:"5px 10px"}} 
                    description="Mandatory Field" 
                    type="error" 
                    showIcon={false} />
                }
                {
                    showMsg &&
                    <div className="formMsg" ref={msgCnt}>
                        <p>This is just for demo purpose. I want to declare that it can be a part of the job. 
                        This is just for demo purpose. I want to declare that it can be a part of the job. 
                        This is just for demo purpose. I want to declare that it can be a part of the job.
                        </p>
                    </div>
                }
            </div>
        </>
    )

}

export default Decisive;