import { useEffect, useRef, useState } from "react";
import { newFormInpPropType } from "../../@types/user";
import { Alert, Button } from "antd";
import { Modal } from "@mui/material";
import { CloseOutlined, RightOutlined } from "@ant-design/icons";
import InputBox from "../input";
import Question from "../../svg/question";
import { useDispatch } from "react-redux";
import { popupInputChange, popupInputVerify } from "../../redux/calculatorSlice";

const Popup = ({activeIndex,index,data,outerIndex,currency}:newFormInpPropType)=>{

    const [showPopup,setPopUp] = useState<boolean>(false);
    const [showMsg,setShowMsg] = useState<boolean>(false);
    const [pass,setPass] = useState(false);
    const msgCnt = useRef<any>();
    const msgRef = useRef<any>();

    const dispatch = useDispatch();


    useEffect(()=>{

        window.addEventListener('click',windowClickFunction);

        return ()=>{
            window.removeEventListener('click',windowClickFunction)
        }

      },[]);

      useEffect(()=>{
        checkPass();
      },[data?.data])

    const windowClickFunction = (e:any)=>{

    if(msgCnt.current && msgRef.current){

        if (!msgCnt.current.contains(e.target) && !msgRef.current.contains(e.target)){
            setShowMsg(false);
        }

    }

    }


    const clickFunc = (e:any)=>{

        setPopUp(true);
    }

    // / function for making bar green or not
    const checkPass = ()=>{
        let c = 0;
        data?.data?.forEach(element => {
            if(element.value){
                c++;
            }
        });

        if(c === data?.data?.length){
            setPass(true);
        }
        else{
            setPass(false);
        }
    }

    const verify = ()=>{
        let err = false;

        if(data?.required){

            data?.data?.forEach(element => {
                if(!element.value){
                    err = true;
                }
            });

            if(err){
                setPass(false);
            }
            else{
                setPass(true);
            }

            dispatch( popupInputVerify({
                value:JSON.stringify({error:err}),
                outerIndex,
                innerIndex : index
            }));

   
        }
        else{

            dispatch( popupInputVerify({
                value:JSON.stringify({error:false}),
                outerIndex,
                innerIndex : index
            }));
            
            checkPass();
        }

        setPopUp(false);
    }

    return(
        <>
        <Modal
            className="modal"
            open={showPopup}
            onClose={verify}
            >
                <div className="modalPopUp">

                <CloseOutlined
                className="popupClose" 
                onClick={()=> {
                    setPopUp(false);
                } }
                />
                    <div className="mpuTop">

                    <div>
                        {
                            data?.data?.map((ielem,iindex)=>(
                                <InputBox
                                key={`otp${iindex}`}
                                type="Cnumber"
                                placeholder={ielem.name}
                                value={ielem.value}
                                onChange={(e:any)=>{

                                    dispatch(popupInputChange({
                                        value : JSON.stringify({data:e.target.value,nestInd:iindex}),
                                        outerIndex,
                                        innerIndex: index
                                    }))
                                 
                               
                                }}
                                percent={ielem.percent}
                                />
                            ))
                        }
                    </div>
              

                    </div>

                    <div className="mpuBot">

                    <Button className="mybtn-blue" type="text" onClick={verify}>
                        Done <RightOutlined />
                    </Button>

                    </div>

                </div>           

            </Modal>
            <div className="formMainCnt">
                <p className="formTitle">{data?.name}</p>
                <div className={
                    `
                    formInputCnt 
                    ${(data?.error) && "doublePopupempty"} 
                    ${(pass) && "doublePopupfilled"}
                 ` 
                }>

                <div
                    className="mainInput myselect"
                    style={{height:"100%",flex:"1",cursor:"pointer",display:"flex",alignItems:"center",color:"gray"}}
                    onClick={clickFunc}
                    >Click to edit</div>

                    <Question className="formQuestion" onClick={()=> setShowMsg(true)}
                    myRef={msgRef} error={data?.error || false} />
                </div>

                {
                    data?.error &&
                    <Alert 
                    style={{fontSize:"12px",padding:"5px 10px"}} 
                    description="One or more sub-fields are Empty!" 
                    type="error" 
                    showIcon={false} />
                }
                {
                    showMsg &&
                    <div className="formMsg" ref={msgCnt}>
                        <p>This is just for demo purpose. I want to declare that it can be a part of the job. 
                        This is just for demo purpose. I want to declare that it can be a part of the job. 
                        This is just for demo purpose. I want to declare that it can be a part of the job.
                        </p>
                    </div>
                }
            </div>
        </>
    )

}

export default Popup;